/* General styling for the ticket-status-log container */
.ticket-status-log {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    font-size: 0.875rem;  /* Smaller font size for the entire section */
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    max-height: 500px;  /* Set a maximum height */
    overflow-y: auto;  /* Enable vertical scrolling */
    background-color: white;
    margin: 0 auto 10px;
  }

  /* Styling for table */
.ticket-status-log table {
    width: 100%;
    border-collapse: collapse;  /* Ensures no double borders */
    margin-bottom: 1rem;
  }

  /* Table Headers */
  .ticket-status-log thead th {
    background-color: #007bff;  /* Blue background */
    color: white;
    font-weight: 600;  /* Bold headers */
    text-align: left;
    padding: 0.5rem;
    font-size: 0.875rem;  /* Smaller font size for headers */
    border-right: 1px solid #ddd;  /* Add line between columns */
  }

  /* Table Body Cells */
  .ticket-status-log tbody td {
    padding: 0.5rem;
    font-size: 0.875rem;  /* Smaller font size for table rows */
    border-top: 1px solid #ddd;  /* Light gray border between rows */
    border-right: 1px solid #ddd;  /* Add line between columns */
  }

  /* Remove border on the last column */
  .ticket-status-log td:last-child,
  .ticket-status-log th:last-child {
    border-right: none;  /* Remove right border for last column */
  }

  /* Row styling for alternating rows */
  .ticket-status-log tr:nth-child(even) {
    background-color: #f2f2f2;  /* Slightly lighter background for even rows */
  }

  /* Styling for "No status available" message */
  .ticket-status-log .text-center {
    font-size: 1rem;  /* Slightly larger font for the no data message */
    color: #6c757d;  /* Gray color for the empty message */
    padding: 1rem 0;
    text-align: center;
  }

  /* Inputs and Dropdowns in TicketStatusLog */
  .ticket-status-log input,
  .ticket-status-log select,
  .ticket-status-log textarea {
    font-size: 0.875rem;  /* Same smaller font for inputs */
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;  /* Light border */
  }

  .ticket-status-log .form-select {
    background-color: #fff;
    font-size: 0.875rem;
  }

  .ticket-status-log .form-control-plaintext {
    background-color: transparent;
    border: none;
  }

  /* Textarea styling */
  .ticket-status-log textarea {
    resize: vertical;
    min-height: 100px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .ticket-status-log {
      padding: 0.5rem;
    }

    .ticket-status-log table {
      font-size: 0.75rem;  /* Even smaller font size on mobile */
    }

    .ticket-status-log td,
    .ticket-status-log th {
      padding: 0.25rem;
    }

    .ticket-status-log .text-center {
      font-size: 0.875rem;  /* Slightly bigger font for the empty message */
    }

    .ticket-status-log input,
    .ticket-status-log select,
    .ticket-status-log textarea {
      font-size: 0.75rem;  /* Smaller font size on mobile */
    }
  }