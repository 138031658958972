/* Base styles */
.leave-request-page{
    padding: 0 5em;
}

.leave-calender {
    padding: 20px;
    background-color: rgb(242, 242, 242);
    display: flex;
    flex-direction: row; /* Align items in a row */
    gap: 20px; /* Optional: adds space between the form and error container */
}

.own-leave-list {
    padding: 20px;
    background-color: rgb(242, 242, 242);
    display: flex; 
}

/* Default layout for large screens */
.calendar-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 7;
    max-width: 70%;
}

.leave-request-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex: 3;
    max-width: 30%;
    padding: 20px;
}

/* Form styling */
.leave-request-form form {
    width: 100%;
    padding: 20px 30px;
}

/* Calendar Header */
.calendar-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.calendar-header button {
    border-radius: 10px;
    background-color: rgb(172, 139, 255);
}

/* Calendar Grid Wrapper */
.calendar-grid-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* Calendar Grid */
.calendar-grid-container {
    border-collapse: collapse;
    margin: 20px 20px;
    font-size: 1.2rem; /* Adjust font size for large screens */
    table-layout: fixed;
    width: 100%;
}

.calendar-grid-container th, .calendar-grid-container td {
    padding: 15px; /* Adjust padding for better spacing */
    border: 1px solid #ddd !important;
    width: 14.28%;
    height: 80px;
    text-align: center;
}

/* Header Cells */
.calendar-grid-container th {
    text-align: center;
    font-weight: bold;
    background-color: #f4f4f4;
}

/* Day Cells */
.calendar-grid-container td {
    text-align: center;
    cursor: pointer;
    padding: 20px;
}

.weekend {
    background-color: rgb(255, 85, 85);
}

.public-holiday {
    background-color: rgb(255, 205, 77);
}

.in-range {
    background-color: rgba(0, 0, 255, 0.2);
}

.start {
    background-color: rgba(0, 255, 0, 0.2);
}

.end {
    background-color: rgba(255, 0, 0, 0.2);
}

.out-range {
    background-color: rgba(130, 130, 130, 0.2);
    pointer-events: none;
    color: #b0b0b0;
}

/* Hover Effect */
.day.active:hover {
    background-color: #e0e0e0;
}

/* Hover Effect for Calendar Cells */
.calendar-grid-container td:hover {
    background-color: #f0f0f0;
}

.leave-status {
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: space-between; /* Space out the items evenly */
    align-items: center; /* Vertically center the items */
    padding: 10px 20px; /* Add padding around the content */
    background-color: #ffffff; /* Light background for the leave status section */
    border-radius: 10px; /* Rounded corners for a soft look */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    width: 100%; /* Use full width of the parent container */
    max-width: 1000px; /* Limit the width to 800px for larger screens */
    margin: 10px auto; /* Center the container and add margin */
    flex-wrap: wrap; /* Allow the items to wrap to the next line if needed */
}

.leave-status h6 {
    margin: 0 15px; /* Space between each item */
    font-size: 1.1rem; /* Slightly larger font size for clarity */
    color: #333; /* Dark text color for readability */
    font-weight: 500; /* Medium weight for the headings */
    font-weight: bold; /* Make the first leave type bold */
}

.leave-status h6:first-child {
    color: #2d2d2d; /* Darker color for the first item */
}

.leave-status h6:last-child {
    color: #2d2d2d; /* Same color for consistency */
}

.leave-status h6::before {
    content: "• "; /* Add a bullet point before each leave type */
    color: #6c63ff; /* A purple bullet for visual appeal */
}

.leave-status h6:last-child::before {
    color: #ff6347; /* Red color for the last bullet to differentiate it */
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .leave-status {
        max-width: 90%; /* Allow the container to take more width on smaller screens */
        padding: 15px; /* Add more padding for better spacing */
        flex-direction: column; /* Stack items vertically on very small screens */
        align-items: flex-start; /* Align items to the left */
    }

    .leave-status h6 {
        font-size: 1rem; /* Slightly smaller font size for smaller screens */
        margin: 10px 0; /* Add vertical margin between items */
    }
}

@media (max-width: 400px) {
    .leave-status {
        max-width: 100%; /* Take full width on very small screens */
        padding: 10px; /* Reduce padding for very small screens */
    }

    .leave-status h6 {
        font-size: 0.9rem; /* Further reduce font size */
        margin: 8px 0; /* Add slight vertical margin */
    }
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
    .leave-calender {
        padding: 3rem 3rem; /* Adjust padding for medium screens */
        flex-direction: column;
    }

    .calendar-picker, .leave-request-form {
        flex: 1; /* Make both the calendar and form take equal width on medium screens */
        max-width: 100%;
    }

    .calendar-grid-container {
        font-size: 1rem; /* Smaller font size for medium screens */
    }

    .calendar-grid-container th, .calendar-grid-container td {
        padding: 12px; /* Reduced padding on medium screens */
    }

    .calendar-header button {
        padding: 8px 12px; /* Smaller button for medium screens */
    }
}

@media screen and (max-width: 768px) {
    .leave-calender {
        padding: 2rem 2rem; /* Reduced padding for smaller screens */
        flex-direction: column;
    }

    .calendar-picker, .leave-request-form {
        max-width: 100%;
        flex: 1; /* Stack the calendar and form vertically */
    }

    .calendar-grid-container {
        font-size: 0.9rem; /* Further reduced font size for smaller screens */
    }

    .calendar-grid-container th, .calendar-grid-container td {
        padding: 10px; /* Smaller padding for mobile */
        height: 60px; /* Adjust height of the calendar cells for mobile */
    }

    .calendar-header button {
        padding: 6px 10px; /* Even smaller button for mobile screens */
    }
}

@media screen and (max-width: 480px) {
    .leave-calender {
        padding: 1rem 1rem; /* Minimal padding for very small screens */
    }

    .calendar-picker, .leave-request-form {
        flex: 1;
        max-width: 100%;
    }

    .calendar-grid-container {
        font-size: 0.8rem; /* Very small font size for small screens */
    }

    .calendar-grid-container th, .calendar-grid-container td {
        padding: 8px; /* Very small padding */
        height: 50px; /* Reduce height of calendar cells for small screens */
    }

    .calendar-header button {
        padding: 5px 8px; /* Tiny button for very small screens */
    }
}
