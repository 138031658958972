/* General Page Styling */
.holiday-page {
    font-family: Arial, sans-serif;
    background-color: #f4f7fc;
    padding: 30px;
}

.holiday-page form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.holiday-page form .form-group {
    margin-bottom: 20px;
}

/* Holiday Name Input */
.holiday-name-input {
    font-size: 16px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.holiday-name-input:focus {
    border-color: #007bff;
    outline: none;
}

/* Holiday Date Input */
.holiday-date-input {
    font-size: 16px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.holiday-date-input:focus {
    border-color: #007bff;
    outline: none;
}

/* Invalid input styling */
.is-invalid {
    border-color: #dc3545;
}

.invalid-feedback {
    font-size: 12px;
    color: #dc3545;
    display: block;
    margin-top: 5px;
}

/* Button Styling */
.add-holiday-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.add-holiday-btn:hover {
    background-color: #0056b3;
}

/* Public Holidays Table Styling */
.public-holidays-table {
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;
}

.public-holidays-table th, .public-holidays-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.public-holidays-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #007bff;
}

.public-holidays-table tr:hover {
    background-color: #f1f1f1;
}

.public-holidays-table td {
    font-size: 14px;
    color: #333;
}

/* Small screens styling */
@media (max-width: 768px) {
    .public-holidays-table th, .public-holidays-table td {
        padding: 8px;
    }

    .add-holiday-btn {
        width: 100%;
        padding: 12px;
        font-size: 18px;
    }

    .holiday-page form {
        padding: 15px;
    }
}
