/* Error.css */
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding: 2rem;
    background: #f8f9fa;
    color: #343a40;
}

.error-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #dc3545;
}

.error-page p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    max-width: 600px;
    line-height: 1.6;
}

/* Optional: Add animation for the redirect message */
.error-page p:last-of-type {
    margin-top: 2rem;
    color: #6c757d;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

/* Optional: Add a progress bar for the redirect timer */
.redirect-progress {
    width: 200px;
    height: 3px;
    background: #e9ecef;
    margin-top: 1rem;
    overflow: hidden;
}

.redirect-progress::after {
    content: '';
    display: block;
    width: 200px;
    height: 3px;
    background: #dc3545;
    animation: progress 3s linear forwards;
}

@keyframes progress {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}